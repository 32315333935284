
import React, { useEffect } from "react";
import { useRef } from "react";
import about from "../../assets/whatWeDo/about2.png";
import './style.css';
import { Link } from "react-router-dom";


export default function About() {


    useEffect(() => {
        const observerOptions = {
            root: null, // relative to the viewport
            rootMargin: '0px',
            threshold: 0.1, // trigger when 10% of the element is in view

        };

        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (!entry.isIntersecting) {
                    console.log('Adding shrink class to', entry.target);
                    entry.target.classList.add('shrink');
                } else {
                    console.log('Removing shrink class from', entry.target);
                    entry.target.classList.remove('shrink');
                }
            });
        }, observerOptions);

        const divs = document.querySelectorAll('.get-smaller');
        divs.forEach(div => {
            observer.observe(div);
        });

        // Cleanup observer on component unmount
        return () => {
            divs.forEach(div => {
                observer.unobserve(div);
            });
        };
    }, []);


    useEffect(() => {
        const handleScroll = () => {
            const elements = document.querySelectorAll('.animated');
            const windowHeight = window.innerHeight;

            elements.forEach(element => {
                const positionFromTop = element.getBoundingClientRect().top;
                const positionFromBottom = element.getBoundingClientRect().bottom;


                if (positionFromTop <= windowHeight && positionFromBottom >= 0) {
                    element.classList.add('in-view');
                } else {
                    element.classList.remove('in-view');
                }
            });


        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleScroll);
        handleScroll(); // Initial check on page load

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleScroll);
        };
    }, []);

    // slide left to right
    // const textRef = useRef(null);
    // const handleIntersection = (entries) => {
    //     const [entry] = entries;
    //     if (entry.isIntersecting) {
    //         entry.target.classList.add('animate-left');
    //     }
    // };
    // useEffect(() => {
    //     const currentRef = textRef.current;
    //     const observer = new IntersectionObserver(handleIntersection);
    //     if (currentRef) {
    //         observer.observe(currentRef);
    //     }
    //     return () => {
    //         if (currentRef) {
    //             observer.unobserve(currentRef);
    //         }
    //     };
    // }, []);
    const textRefs = useRef([]);

    const handleIntersection = (entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                entry.target.classList.add('animate-left');
            }
        });
    };

    useEffect(() => {
        const observer = new IntersectionObserver(handleIntersection);

        textRefs.current.forEach(ref => {
            if (ref) {
                observer.observe(ref);
            }
        });

        return () => {
            textRefs.current.forEach(ref => {
                if (ref) {
                    observer.unobserve(ref);
                }
            });
        };
    }, []);



    //   useEffect(() => {
    //       const observerOptions = {
    //           root: null, // relative to the viewport
    //           rootMargin: '0px',
    //           threshold: 0.1, // trigger when 10% of the element is in view

    //       };
    //       const observer2 = new IntersectionObserver((entries, observer) => {
    //             entries.forEach(entry => {
    //                 if(!entry.isIntersecting) {
    //                     console.log('adding ball');
    //                     entry.target.classList.add('shrink');
    //                 } else {
    //                     console.log('removing ball');
    //                     entry.target.classList.remove('shrink')
    //                 }
    //             })
    //       }, observerOptions)

    //       const balls = document.querySelectorAll('.ball');
    //       balls.forEach(ball => {
    //             observer2.unobserve(ball)
    //       })
    //   }, [])




    return (
        <div className="grid justify-items-center w-full p-0 m-0 relative ">
            {/* IMAGE */}
            <img src={about} alt="about" className="w-full about-landing-image" />
            {/* <div className="border-4 rounded-full w-screen h-screen  ball sticky"></div> */}
            {/* <div className="container">
                <img src={about} alt="about" className="background-image" />
                <div className="scrollable-content">
                    <div
                        className="ball"
                        style={{ transform: `translateY(${Math.min(scrollY, window.innerHeight)}px) translateX(-50%)` }}
                    />
                </div>
            </div> */}


            {/* DESCRIPTION */}
            <div className="container bg-white text-lg p-24 gap-24 grid pb-72 justify-items-center font-inria-bold min-h-screen">
                {/* <h1 className="title text-blue-950 text-4xl text-center">ERA Software Solutions</h1> */}
                <div className=" divs font-medium  get-smaller bg-white p-48">
                    <p className="description text-[24px] text-center leading-10">At ERA, we craft innovative software solutions designed to exceed your expectations. We build strong client
                        relationships through exceptional service, ensuring your customers love your brand. We’re here to support you,
                        even watering your plants while you’re away, because at ERA, community matters.
                    </p>
                </div>

                <div className="p-24 highlight">
                    <h1 className="uppercase text-5xl animated ">delighted to hear.</h1>
                </div>

                {/* ----------------------------------------------------- */}
                {/* <div className="gap-32 grid justify-items-center">
                    <div className="sticky top-12 z-[10] md:top-[20vh] divs font-medium rounded-2xl w-3/5 p-16 get-smaller bg-white">
                        <h1 className="title text-3xl font-semibold text-center">Welcome to ERA Software Solutions</h1><br />
                        <p className="description text-lg text-center leading-8">
                            Where innovation meets expertise in the dynamic world of software
                            development. Established in 2018, ERA Software Solutions has been
                            a trailblazer in crafting bespoke software solutions for the
                            automotive industry. As we celebrate five years of excellence, our
                            commitment to pushing the boundaries of what is possible in software
                            has never been stronger.
                        </p>
                    </div> */}
                {/* --------------------------------------------------------------------------------------------------------------------------------------- */}
                <div className="gap-32 grid justify-items-center">
                    <div className="sticky top-12 z-[10] md:top-[30vh] divs font-medium rounded-2xl w-3/5 p-16 get-smaller bg-white">
                        <h1 className="title text-3xl font-semibold text-center">Celebrating Six Years of Innovation</h1><br />
                        <p className="description text-lg text-center leading-8">
                            Since 2018, ERA Software Solutions has been at the forefront of crafting bespoke software solutions for the automotive industry.
                            As we celebrate six years of excellence, our commitment to pushing the boundaries of what is possible in software has never been
                            stronger. Innovation and expertise continue to drive us forward in the dynamic world of software development.
                        </p>
                    </div>

                    {/* ----------------------------------------------------------- */}

                    <div className="sticky top-12 divs z-[10] md:top-[30vh] font-medium rounded-2xl w-3/5 p-16  get-smaller bg-white">
                        <h1 className="title text-3xl font-semibold text-center">Our Journey</h1><br />
                        <p className="description text-lg text-center">
                            In the intricate landscape of the automotive sector,
                            ERA Software Solutions emerged with a mission to transform ideas
                            into reality. Over the years, we have honed our skills, gained
                            invaluable insights, and earned the trust of our clients through a
                            dedication to excellence and innovation.
                        </p>
                    </div>

                    <div className="sticky top-12 divs z-[10] md:top-[30vh] font-medium rounded-2xl w-3/5 p-16 get-smaller bg-white">
                        <h1 className="title text-3xl font-semibold text-center">Expanding Horizons</h1><br />
                        <p className="description text-lg text-center">
                            Now, as we reach this significant milestone, ERA
                            Software Solutions is excited to announce our strategic expansion.
                            We are assembling a dynamic team of skilled programmers ready to
                            contribute their expertise to a diverse range of software projects,
                            collaborating with project companies and startups alike.
                        </p>
                    </div>

                    {/* <div className="sticky top-12 divs z-[10] md:top-[20vh] font-medium rounded-2xl w-3/5 p-16 get-smaller bg-white">
                        <h1 className="title text-3xl font-semibold text-center">Why ERA Software Solutions</h1><br />
                        <p className="description text-lg text-center">
                            Automotive Expertise: Our roots in the
                            automotive industry provide us with a profound understanding of
                            complex technological landscapes, ensuring that our software
                            solutions are not just innovative but precisely tailored to your
                            unique needs. <br /><br />
                            Innovation at Core: ERA Software Solutions thrives on
                            innovation. Our team stays ahead of technological trends, ensuring
                            that your projects are equipped with cutting-edge solutions. <br /><br />
                            Collaborative Excellence: We believe in the power of collaboration.
                            Our programmers bring a wealth of experience and creativity, working
                            seamlessly with your team to bring your software projects to
                            fruition. <br /><br />
                            Proven Track Record: With a history of successful software
                            deliveries, ERA Software Solutions is a trusted partner in turning
                            visions into reality.
                        </p>
                    </div> */}

                    <div className="sticky top-12 divs z-[10] md:top-[30vh] font-medium rounded-2xl w-3/5 p-16 get-smaller bg-white">
                        <h1 className="title text-3xl font-semibold text-center">Join Us on this Exciting Journey</h1><br />
                        <p className="description text-lg text-center">
                            As we enter this new phase, ERA
                            Software Solutions invites you to partner with us. Whether you
                            represent a seasoned software project company or a dynamic startup,
                            let's collaborate to bring your software projects to new heights.
                            Join us on this exciting journey of innovation, collaboration, and
                            software excellence.
                        </p>
                    </div>
                </div>
            </div>


            <div className="font-inria-bold">
                <div className="font-medium w-screen h-screen p-60 bg-black text-white flex flex-col items-center justify-center animated whyEra">
                    <h1 className="title text-3xl font-semibold text-center p-12 animated">Why ERA Software Solutions</h1>
                    <div className="description text-lg text-center w-3/5 leading-8">
                        <p className="p-8 animated ">
                            <span className="font-extrabold text-xl">Automotive Expertise:</span> Our roots in the
                            automotive industry provide us with a profound understanding of
                            complex technological landscapes, ensuring that our software
                            solutions are not just innovative but precisely tailored to your
                            unique needs.
                        </p>

                        <p className="p-8 animated">
                            <span className="font-extrabold text-xl">Innovation at Core:</span> ERA Software Solutions thrives on
                            innovation. Our team stays ahead of technological trends, ensuring
                            that your projects are equipped with cutting-edge solutions.
                        </p>

                        <p className="p-8 animated">
                            <span className="font-extrabold text-xl">Collaborative Excellence: </span>We believe in the power of collaboration.
                            Our programmers bring a wealth of experience and creativity, working
                            seamlessly with your team to bring your software projects to
                            fruition.
                        </p>

                        <p className="p-8 animated">
                            <span className="font-extrabold text-xl"> Proven Track Record:</span> With a history of successful software
                            deliveries, ERA Software Solutions is a trusted partner in turning
                            visions into reality.
                        </p>
                    </div>
                </div>

            </div>


            {/* KUDOS */}
            <div>
                <div className="p-24 w-screen h-screen kudos-wrapper">
                    <div className="kudos p-12" ref={el => textRefs.current[0] = el}>
                        <h1 className="kudos uppercase text-6xl font-extrabold">kudos!</h1>
                    </div>

                    <div className="kudos-description-wrap p-12 w-1/2" ref={el => textRefs.current[1] = el}>
                        <p className="kudos-description uppercase text-4xl font-extrabold leading-relaxed">Feeling chatty? Drop us a line and let’s make some magic happen!

                        </p>
                    </div>
                    <div className="p-12 " ref={el => textRefs.current[2] = el}>
                        <button className="rounded-lg bg-black text-white font-semibold w-32 h-12 button2">
                            <Link to={"/contact"} >Contact us</Link>
                        </button>
                    </div>
                </div>

            </div>
        </div>
    );
}