

import '../../src/global.css';
import logo from "../assets/logo-greek-2.png";
import logo2Image from "../assets/ERA.png";
import React, { useRef,  useEffect } from 'react';
import { Link } from 'react-router-dom';
import { gsap } from 'gsap';



function Header({ isServicePage, timeline, ease }) {
 

    const logoRef = useRef(null);
    const aboutLinkRef = useRef(null);
    const servicesLinkRef = useRef(null);
    const contactLinkRef = useRef(null);

    useEffect(() => {
        console.log("Header useEffect triggered");
        gsap.set([logoRef.current, aboutLinkRef.current, servicesLinkRef.current, contactLinkRef.current], { y: 0 });
        timeline.from(logoRef.current, {
            duration: 1,
            opacity: 1,
            y: 100,
            ease: ease,
            
        },
   );

        timeline.from([aboutLinkRef.current, servicesLinkRef.current, contactLinkRef.current], {
            duration: 2,
            opacity: 1,
            y: -100,
            stagger: {
                amount: 0.4
            },
            ease: ease,
            
        });
        

    }, [timeline, ease]);




    return (
        <header className={`header ${isServicePage ? 'bg-black' : 'bg-white'}`}>
            <div className="relative">
                <div className="header grid grid-cols-2 static top-0 left-0 right-0">
                    <div className="titleLogo " ref={logoRef}>
                        <Link to='/'>
                            <img src={isServicePage ? logo2Image : logo} alt="logo" className="h-20 w-32 p-1" />
                        </Link>
                    </div>
                    <div className={`navBar text-${isServicePage ? 'black' : 'white'} grid items-center justify-end mr-8`}>
                        <ul className="grid grid-cols-3 gap-10 font-inria text-lg">
                            <li>
                                <Link className={`aboutLink ${isServicePage ? 'underline-service' : 'underline'} ${isServicePage ? 'text-white' : 'text-black'}`} to='/about' ref={aboutLinkRef}> ABOUT</Link>
                            </li>
                            <li>
                                <Link className={`servicesLink ${isServicePage ? 'underline-service' : 'underline'} ${isServicePage ? 'text-white' : 'text-black'}`} to='/services' ref={servicesLinkRef}> SERVICES</Link>
                            </li>
                            <li>
                                <Link className={`contactLink ${isServicePage ? 'underline-service' : 'underline'} ${isServicePage ? 'text-white' : 'text-black'}`} to='/contact' ref={contactLinkRef}> CONTACT</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;
