import { Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import Main from './pages/home/home';
import About from './pages/about/about';
import Header from './components/header';
import Footer from './components/footer';
import Contact from './pages/contact/contact';
import Service from './pages/services/services';
import { gsap, Power3 } from 'gsap';




function App() {
  // GSAP timeline and ease function
  var tl = gsap.timeline({repeat: 0});
  const ease = Power3.easeOut;
  const location = useLocation();

  const isServicePage = location.pathname === '/services';

  return (
    <div className="app">
      <Header isServicePage={isServicePage} timeline={tl} ease={ease} />
      <Routes>
      
          <Route path='/' element={<Main />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/services' element={<Service />} />
        
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
