
import React from "react";
import { useEffect } from "react";
import '../../../src/global.css';
import handshake from '../../assets/videos/handshake.mp4'
import { Link } from "react-router-dom";

import peopleFocused from '../../assets/setApart/focusPeople.png';
import scaledDevelopment from '../../assets/setApart/analyst.png';
import clarity from '../../assets/setApart/clarity.png';
import excellence from '../../assets/setApart/techExcellency1.png';
import process from '../../assets/setApart/process.png';

import Articles from "../../components/articles"

import './style.css';
// import Typewriter from "../../components/typeWriter";
import Typewriter from 'typewriter-effect';



const Main = () => {
    // scroll
    useEffect(() => {
        const handleScroll = () => {
            const elements = document.querySelectorAll('.animated');
            const windowHeight = window.innerHeight;

            elements.forEach(element => {
                const positionFromTop = element.getBoundingClientRect().top;
                const positionFromBottom = element.getBoundingClientRect().bottom;


                if (positionFromTop <= windowHeight && positionFromBottom >= 0) {
                    element.classList.add('in-view');
                } else {
                    element.classList.remove('in-view');
                }
            });


        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleScroll);
        handleScroll(); // Initial check on page load

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleScroll);
        };
    }, []);

    // const textRef2 = useRef(null)
    // const handleIntersection2 = (entries) => {
    //     const [entry] = entries;
    //     if (entry.isIntersecting) {
    //         entry.target.classList.add('section3-reveal');
    //     }
    // };
    // useEffect(() => {
    //     const currentRef2 = textRef2.current;
    //     const observer = new Intersection    // Remove the unused function declaration     });

    //     if (currentRef2) {
    //         observer.observe(currentRef2);
    //     }

    //     return () => {
    //         if (currentRef2) {
    //             observer.unobserve(currentRef2);
    //         }
    //     };
    // }, []);



    // section-2 text comes from left to right
    // const textRef = useRef(null);
    // const handleIntersection = (entries) => {
    //     const [entry] = entries;
    //     if (entry.isIntersecting) {
    //         entry.target.classList.add('animate-left');
    //     }
    // };
    // useEffect(() => {
    //     const currentRef = textRef.current;
    //     const observer = new IntersectionObserver(handleIntersection);
    //     if (currentRef) {
    //         observer.observe(currentRef);
    //     }
    //     return () => {
    //         if (currentRef) {
    //             observer.unobserve(currentRef);
    //         }
    //     };
    // }, []);

    // defRef for section set apart - low cost - high end
    // const defRef = useRef(null)
    // const handleIntersection3 = (entries) => {
    //     const [entry] = entries;
    //     if (entry.isIntersecting) {
    //         entry.target.classList.add('scale-animation')
    //     }
    // };
    // useEffect(() => {
    //     const currentDefRef = defRef.current;
    //     const observer = new IntersectionObserver(handleIntersection3, {
    //         root: null, // use the viewport as the container
    //         rootMargin: '0px',
    //         threshold: 0.1 // trigger when 10% of the element is visible
    //     });
    //     if (currentDefRef) {
    //         observer.observe(currentDefRef);
    //     } return () => {
    //         if (currentDefRef) {
    //             observer.unobserve(currentDefRef)
    //         }
    //     }
    // })

    // const text = 'Unleash the geek-chic magic with our software sorcery. Your business, our playground. Let the tech fun begin!';


    return (

        <div className="home-page">
            {/* ----------------section1------------- */}
            <div className="main grid justify-center items-center text-center p-10 m-20 pt-32 eureka-wrapper">
                <div className="headline">
                    <h1 className="font-black text-8xl"> EUREKA!
                    </h1>
                </div>

                {/* <div className="text-4xl mx-auto p-7 font-light leading-normal w-1/2 content typewriter">
                    <p className="font-italiana">
                        Unleash the geek-chic magic with our software sorcery. Your business, our playground. Let the tech fun begin!
                    </p>
                </div> */}
                {/* 
                <div className="text-4xl mx-auto p-7 font-light leading-normal w-1/2 content ">
                    <p className="font-italiana">
                        <Typewriter text={text} speed={100}/>
                        
                    </p>
                </div> */}


                <div className="text-4xl mx-auto p-7 font-light leading-normal w-1/2 content ">
                    <p className="font-italiana">
                        <Typewriter
                            options={{
                                delay: 50, // adjust this value to control typing speed
                            }}
                            onInit={(typewriter) => {
                                typewriter.typeString('Unleash the geek-chic magic with our software sorcery. Your business, our playground. Let the tech fun begin!')
                                    .callFunction(() => {
                                        console.log('String typed out!');
                                    })

                                    .start();


                            }}
                        />

                    </p>
                </div>

            </div>


            {/* section2 - about*/}
            <div className="page2 grid grid-cols-2 p-32 pb-40 mb-28 font-inria text-2xl justify-center items-center ">
                {/* slide text from left to right -> ref={textRef} */}
                <div className="info p-8 pr-20 animated " >
                    <p>At <strong>ERA</strong>, we don't just create software; we sculpt digital solutions that breathe life into your visions.</p> <br />

                    <p>Beyond being expert developers, we also take pride in being the go-to subcontractor for project development
                        companies, offering our proficiency and passion to amplify their success.
                    </p> <br />
                    <p>Whether you're embarking on a full-scale software development journey or seeking <strong>reliable</strong> programming
                        support to enhance your project, we stand as your steadfast ally. </p> <br />

                    <p>Let's not just write code; let's craft excellence together – because at ERA, your projects become our shared triumphs.</p>
                    <div className="pt-12 button-wrapper">
                        <button className=" rounded-md bg-yellow-200 w-44 h-12 animated"><Link to={"/about"} >Learn More</Link></button>
                    </div>
                </div>
                <div className="video p-14  grid justify-center items-center animated">
                    {/* <video className=" rounded-2xl" width={380} height={430} controls={false} autoPlay={true} src={handshake}></video> */}
                    <video className="rounded-2xl" width={380} height={430} autoPlay muted src={handshake} playsInline loop></video>

                </div>


            </div>




            {/* SECTION 3- WHAT WE DO */}
            <div className="p-32 bg-black text-white w-screen h-screen whatWeDo">
                <div>
                    <div className="text-center font-extrabold text-3xl pb-12 animation-reveal-content animation-hide-content animated whatWeDo-highlight">
                        <h1>WHAT WE DO</h1>
                    </div>

                    <div>
                        <div className="grid grid-cols-2 grid-rows-2 gap-10 p-10 whatWeDoService">
                            <div className="border-box">
                                <div className="uppercase font-bold text-2xl text-center pb-8 animated">
                                    <h1>software Development</h1>
                                </div>

                                <div className="animated">
                                    <p>We craft bespoke software solutions to fit your business like a glove, ensuring they're scalable,
                                        user-friendly, and top-notch from start to finish—because your business deserves software that's as unique as you are!
                                    </p>
                                </div>
                            </div>

                            <div className="border-box">
                                <div className="uppercase font-bold text-2xl text-center pb-8 animated">
                                    <h1>Web Development</h1>
                                </div>

                                <div className="animated">
                                    <p>Our web development services are designed to create engaging, responsive, and user-friendly websites. We focus on delivering websites that
                                        not only look impressive but also provide an exceptional user experience, ensuring your online presence stands out.
                                    </p>
                                </div>
                            </div>

                            <div className=" border-box">
                                <div className="uppercase font-bold text-2xl text-center pb-8 animated">
                                    <h1>Application Development</h1>
                                </div>

                                <div className="animated">
                                    <p>We develop high-quality applications for mobile and desktop platforms. Our application development team is dedicated to creating intuitive and
                                        efficient apps that enhance user engagement and streamline business operations.
                                    </p>
                                </div>
                            </div>

                            <div className=" border-box">
                                <div className="uppercase font-bold text-2xl text-center pb-8 animated">
                                    <h1>Software Support and Maintenance</h1>
                                </div>

                                <div className="animated">
                                    <p>Our software support and maintenance services ensure your systems are always up and running smoothly. We provide comprehensive support to address
                                        any issues promptly and perform regular maintenance to keep your software optimized and secure.
                                    </p>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </div>




            {/* SET APART */}
            <div className="pt-40 pb-32 font-inria-bold">
                <div className="description  flex justify-center items-center animated">
                    <h1 className="text-5xl leading-relaxed text-center text-shadow  p-12 set-apart-text"> Surpassing the Rest... Our Competitive  Edge is simple: <br />
                        {/* if you want to make bigger for low cost part add => ref={defRef}*/}
                        <span className="block text-red-600 mx-auto" >low cost & high end</span>
                    </h1>
                </div>

                <div className="slider">
                    <div className="slide-track">

                        <div className="slide ">
                            <div ><img src={peopleFocused} alt="peopleFocused" /></div>
                            <div><p>People-focused</p></div>

                        </div>

                        <div className="slide">
                            <div ><img src={scaledDevelopment} alt="scaleDevelopment" /></div>
                            <div> <p>Scaled development</p></div>
                        </div>

                        <div className="slide">
                            <div ><img src={clarity} alt="clarity" /></div>
                            <div> <p>Total clarity</p></div>
                        </div>

                        <div className="slide">
                            <div ><img src={excellence} alt="excellence" /></div>
                            <div><p>Excellence in technology</p></div>
                        </div>

                        <div className="slide">
                            <div > <img src={process} alt="process" /></div>
                            <div><p>Strongly focused <br /> on procedures</p></div>
                        </div>

                        {/* SAME */}
                        <div className="slide ">
                            <div ><img src={peopleFocused} alt="peopleFocused" /></div>
                            <div><p>People-focused</p></div>

                        </div>

                        <div className="slide">
                            <div ><img src={scaledDevelopment} alt="scaleDevelopment" /></div>
                            <div> <p>Scaled development</p></div>
                        </div>

                        <div className="slide">
                            <div ><img src={clarity} alt="clarity" /></div>
                            <div> <p>Total clarity</p></div>
                        </div>

                        <div className="slide">
                            <div ><img src={excellence} alt="excellence" /></div>
                            <div><p>Excellence in technology</p></div>
                        </div>

                        <div className="slide">
                            <div> <img src={process} alt="process" /></div>
                            <div><p>Strongly focused <br /> on procedures</p></div>
                        </div>
                    </div>
                </div>
            </div>






            {/* section 5 - ARTICLES */}
            {/* <div className="p-20 pt-52 animated">
                <Articles />
            </div> */}

        </div >
    );
}

export default Main;