import React, { useState } from "react";
import emailjs from "emailjs-com";
import './style.css';

// Initialize emailjs with environment variables
emailjs.init(process.env.REACT_APP_YOUR_PUBLIC_KEY);

export default function Contact() {
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);


    const [formData, setFormData] = useState({
        fullName: "",
        email: "",
        company: "",
        phone: "",
        // file: null,
        message: ""
    });


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    }

    // const handleFileChange = (e) => {
    //     setFormData({
    //         ...formData,
    //         file: e.target.files[0]
    //     });
    // }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);
        setLoading(true)
        setError(null)
        setFormSubmitted(false);

        // Prepare the email template parameters
        const templateParams = {
            fullName: formData.fullName,
            email: formData.email,
            company: formData.company,
            phone: formData.phone,
            message: formData.message
        };

        // Send the email
        emailjs.send(process.env.REACT_APP_YOUR_SERVICE_ID, process.env.REACT_APP_YOUR_TEMPLATE_ID, templateParams, process.env.REACT_APP_YOUR_PUBLIC_KEY)

            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                setFormSubmitted(true);
                setLoading(false)
            }, (error) => {
                console.log('FAILED...', error);
                setError('Failed to send message. Please try again later.');
                setLoading(false);
            });
    }

    return (
        <div className="contact bg-white  p-12 grid justify-center">

            {/* Contact Information */}
            <div className=" info-part  p-24  grid grid-col-1 gap-12 justify-center w-screen h-screen">
                <div className="text-8xl font-black justify-center text-center">
                    <h1 className="uppercase leading-relaxed">We're ready to <br/>listen!</h1>
                    
                </div>

                <div className="justify-center text-center text-3xl leading-relaxed">
                    <h1>Reach out to us at</h1>
                    <p>info@eraautomation.co.uk</p>
                    <p>+44 7923474222</p>
                </div>

                
            </div>

            {/* Contact Form */}
            <div className="form-part p-12 pb-12  justify-center items-center grid text-2xl gap-16">
                {formSubmitted ? (
                    <div className="text-center ">
                        <h2 className="text-4xl ">Thank you for your message! We will get back to you soon.</h2>
                    </div>
                ) : (
                    <>
                        <div className="grid justify-center text-center font-bold text-lg gap-1 pb-10">
                                <h1 className="text-4xl pb-10 capitalize">Please fill out this form</h1>
                                
                        </div>

                        <div>
                            <form className="grid gap-5" onSubmit={handleSubmit}>
                                    <div className=" grid gap-16  form-part">
                                    <input
                                            className="border border-black border-s-0 border-e-0 border-t-0  h-16 pl-2"
                                        type="text"
                                        name="fullName"
                                        placeholder="Full Name*"
                                        required
                                        value={formData.fullName}
                                        onChange={handleChange}
                                    />

                                    <input
                                            className="border border-black border-s-0 border-e-0 border-t-0  h-16 pl-2"
                                        type="email"
                                        name="email"
                                        placeholder="Email*"
                                        required
                                        value={formData.email}
                                        onChange={handleChange}
                                    />


                                    <input
                                            className="border border-black border-s-0 border-e-0 border-t-0  h-16 pl-2"
                                        type="tel"
                                        name="phone"
                                        placeholder="Phone"
                                        value={formData.phone}
                                        onChange={handleChange}
                                    />

                                    <input
                                            className="border border-black border-s-0 border-e-0 border-t-0  h-16 pl-2"
                                        type="text"
                                        name="company"
                                        placeholder="Company"
                                        value={formData.company}
                                        onChange={handleChange}
                                    />
                                    {/* 
                                    <input
                                        className="input-default border border-black rounded-lg h-10 p-1 pl-2"
                                        type="file"
                                        name="file"
                                        onChange={handleFileChange}
                                    /> */}

                                    <textarea
                                            className="border border-black border-s-0 border-e-0 border-t-0  h-32 pl-2"
                                        name="message"
                                        placeholder="Your message...*"
                                        required
                                        value={formData.message}
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="submit-button">
                                    <button
                                        className="border w-full h-12 rounded-lg bg-black text-white"
                                        type="submit"
                                    >
                                        {loading ? 'Submitting...' : 'Submit'}

                                    </button>
                                </div>
                            </form>
                            {error && <div className="text-red-500 mt-2">{error}</div>}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

