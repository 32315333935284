import React from "react";
import image1 from '../../assets/services/social-media.png';
import image2 from '../../assets/services/software.png';
import video1 from '../../assets/services/app.mp4';
import video2 from '../../assets/services/recipe-cideo.mp4';
import image3 from '../../assets/support.png'
import '../../global.css';
import { useEffect } from "react";
import './style.css';



export default function Service() {
    // scroll
    useEffect(() => {
        const handleScroll = () => {
            const elements = document.querySelectorAll('.animated');
            const windowHeight = window.innerHeight;

            elements.forEach(element => {
                const positionFromTop = element.getBoundingClientRect().top;
                const positionFromBottom = element.getBoundingClientRect().bottom;


                if (positionFromTop <= windowHeight && positionFromBottom >= 0) {
                    element.classList.add('in-view');
                } else {
                    element.classList.remove('in-view');
                }
            });


        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleScroll);
        handleScroll(); // Initial check on page load

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleScroll);
        };
    }, []);

    return (
        <div className="">
            {/* HIGHLIGHT */}
            <div className="services-landing w-screen h-full items-center justify-center text-center text-white bg-black pb-48 mb-20 pt-48" >
                <h1 className="highlight-title-description mx-auto  max-w-[1100px] text-[32px] uppercase leading-[105%] lg:text-[60px]"> Code & Comedy: Where Software, Apps,
                    and Websites Meet Laughter!
                </h1><br />
                <h1 className="highlight-description mx-auto mt-6 max-w-[600px]  lg:mt-8">Step into our world of tech wizardry and witty banter, where we craft software,
                    apps, and websites with a generous sprinkle of humor.
                    Join us as we navigate the digital landscape with a smile, turning your visions into virtual reality one laugh at a time. Let's
                    code, create, and crack jokes together!
                </h1>
            </div>

            {/* SERVICES */}
            <div className="services grid gap-20 bg-white p-14 m-10">
                 
                 {/* PROVIDE PROGRAMMER */}
                <div className="service grid grid-cols-2 gap-10">
                  
                    <div className="flex flex-col items-center justify-center h-full " id="softwareDev">
                        <div className="content-group">
                            <h1 className="text-3xl font-bold animated uppercase">Accelerated Project Support</h1> <br />
                            <h2 className="text-2xl font-semibold text-center animated">Empower Your Team with Skilled Developers</h2> <br />
                            <p className="text-lg text-center animated">
                                Running a software development business? If you’re looking for extra manpower, we can provide experienced, highly 
                                skilled developers to extend your team and help you scale effortlessly. Our programmers are ready to bring your 
                                vision to life! Whether you're facing tight deadlines, complex projects, or simply need to expand your capacity, 
                                our developers are equipped with the expertise to seamlessly integrate into your team. <br/> <br/>
                                Let’s build something great together.

                            </p>
                        </div>
                    </div>

                    <div className="service-media animated">
                        <img src={image3} alt="image3" width={550}></img>
                    </div>
                </div>

                {/* SOFTWARE DEVELOPMENT */}
                <div className="service grid grid-cols-2 gap-10">
                    <div className="service-media animated">
                        <img src={image1} alt="image1" width={500}></img>
                    </div>

                    <div className="flex flex-col items-center justify-center h-full " id="softwareDev">
                        <div className="content-group">
                            <h1 className="text-3xl font-bold animated uppercase">Software Development</h1> <br/>
                            <h2 className="text-2xl font-semibold text-center animated">Tailored Solutions for Your Unique Needs</h2> <br/>
                            <p className="text-lg text-center animated">
                                At ERA we specialize in creating custom software solutions designed to meet the specific
                                requirements of your business. Our expert team works closely with you to develop scalable,
                                user-friendly, and high-quality software that enhances productivity and adapts to your
                                evolving needs. From initial consultation through to development, deployment, and ongoing
                                support, we provide end-to-end services to ensure your success.
                            </p>
                        </div>
                    </div>
                </div>

                {/* WEB DEVELOPMENT */}
                <div className="service grid grid-cols-2 gap-10" id="webDev">
                    <div className="flex flex-col items-center justify-center h-full ">
                        <div className="content-group">
                            <h1 className="text-3xl font-bold animated uppercase">Web Development</h1> <br/>
                            <h2 className="text-2xl font-semibold animated">Web Portal Development</h2>
                            <p className="text-lg text-center animated">
                                We build dynamic web portals that streamline information access and enhance user interaction
                                for businesses and communities. Funny One-Liner: Creating web portals that make info overload
                                feel like a spa day for your brain!
                            </p> <br/>
                        </div>
                        <div className="content-group">
                            <h2 className="text-2xl font-semibold animated">Web Application Development</h2>
                            <p className="text-lg text-center animated">
                                Our team develops robust web applications that are tailored to your business needs, providing
                                seamless functionality and user experience. Funny One-Liner: Crafting web apps so smooth, you’ll
                                think they’re butter on a hot pancake!
                            </p>
                        </div>
                    </div>
                    <div className="service-media flex items-end justify-end animated">
                        <video src={video1} autoPlay muted width={500} playsInline loop></video>
                    </div>
                </div>

                {/* APP DEVELOPMENT */}
                <div className="service grid grid-cols-2 gap-10" id="appDev">
                    <div className="service-media animated">
                        <img src={image2} alt="image2" width={500}></img>
                    </div>

                    <div className="flex flex-col items-center justify-center h-full ">
                        <div className="content-group">
                            <h1 className="text-2xl font-bold animated uppercase">Application Development</h1><br />
                            <h2 className="text-2xl font-semibold animated">Mobile Application Development</h2>
                            <p className="text-lg text-center animated">
                                We design and develop mobile applications that provide exceptional user experiences on both
                                iOS and Android platforms. Funny One-Liner: Making mobile apps so addictive, even your phone
                                needs a timeout!
                            </p><br />
                        </div>
                        <div className="content-group">
                            <h2 className="text-2xl font-semibold animated">Desktop Application Development</h2>
                            <p className="text-lg text-center animated">
                                Our desktop applications are designed to enhance productivity and performance, customized to
                                your specific business requirements. Funny One-Liner: Building desktop apps that make your
                                computer feel like it just got a promotion!
                            </p>
                        </div>
                    </div>
                </div>

                {/* SUPPORT AND MAINTENANCE */}
                <div className="service grid grid-cols-2 gap-10" id="support">
                    <div className="flex flex-col items-center justify-center h-full">
                        <div className="content-group">
                            <h1 className="text-2xl font-bold animated uppercase">Software Support and Maintenance</h1><br />
                            <h2 className="text-2xl font-semibold animated">Software Support</h2>
                            <p className="text-lg text-center animated">
                                Our dedicated support team ensures your software runs smoothly, providing timely assistance
                                whenever you need it. Funny One-Liner: Think of us as your software’s personal butler, always
                                ready to help!
                            </p><br />
                        </div>
                        <div className="content-group">
                            <h2 className="text-2xl font-semibold animated">Software Maintenance</h2>
                            <p className="text-lg text-center animated">
                                We offer comprehensive software maintenance services to keep your systems up-to-date, secure,
                                and running efficiently. Funny One-Liner: Keeping your software in tip-top shape, like a
                                tech-savvy fairy godmother!
                            </p>
                        </div>
                    </div>

                    <div className="service-media flex items-end justify-end animated">
                        <video src={video2} autoPlay muted width={500} playsInline loop></video>
                    </div>
                </div>

            </div>


        </div>
    )

}