
import { BsLinkedin } from "react-icons/bs";
import { IoIosMail } from "react-icons/io";
import { FaSquarePhone } from "react-icons/fa6";
import { FaLocationDot } from "react-icons/fa6";
import '../global.css';

export default function Footer() {
 

    return (
        <div className="footer-wrapper bg-black text-white pb-8 pt-8">

            <div className="wrapper grid grid-cols-3">

                <div className="title-wrapper grid  place-items-center pl-10">
                    <div className="title-era text-5xl font-bold ">
                        <h1>ERA Automation & Software</h1>
                    </div>
                </div>

                <div className="infos-wrapper grid  place-items-start pl-16">
                    <div className="flex items-center pb-4">
                        <IoIosMail className="mr-2" />
                        <p>info@eraautomation.co.uk</p>
                    </div>

                    <div className="flex items-center pb-4">
                        <FaSquarePhone className="mr-2" />
                        <p>+44 7923474222</p>
                    </div>

                    <div className="flex items-center pb-4">
                        <FaLocationDot className="mr-2" />
                        <p>151 West Green Road, London, England, N15 5EA</p>
                    </div>


                </div>
                <div className="grid instagram-wrapper ">
                    <div className="grid  place-items-center instagram-button">
                        {/* <p>Follow us</p> */}

                        <a
                            href="https://www.linkedin.com/company/era-soft/"
                            target="_blank"
                            rel="noreferrer"
                            className="instagram-logo"
                        >
                            <BsLinkedin className="text-2xl" />
                        </a>
                    </div>
                </div>

            </div>




            <div className="copy-right pt-10">
                <div className="">
                    <p className="text-center text-white">
                        Copyright &copy; 2024 Era Automation Software Ltd
                    </p>
                </div>
            </div>

        </div>
    );
}
